import React from "react";

import companyLogo from "../flahybaseLogo.png"; // Replace with the actual path to your JPG image

export const CompanyLogo = (props) => (
  <>
    {/* <Image width={200} src={companyLogo}></Image> */}
    <img
      src={companyLogo}
      alt="Flahybase Logo"
      height={props.height}
      width={props.width}
    />
  </>
);
