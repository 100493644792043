import React from "react";
export default function AppFooter() {
  const currentYear = new Date().getFullYear();

  return (
    <p className="p-4 m-0 bg-white custom-font items-center text-center text-black border-t-slate-200 border-1">
      &copy; {currentYear} Flahybase [Legal Name: Flahy Inc., DBA: Flahybase].
      All rights reserved.
    </p>
  );
}
