import React from "react";
import NavigationBar from "./Components/mainNavigationBar";
import { Layout } from "antd";
import AppFooter from "./Components/Footer";
const { Header, Content, Footer } = Layout;
export const Clinics = () => (
  <>
    <Layout className="flex flex-col font-futura min-h-screen bg-white">
      <Header className="flex w-full p-0 bg-white">
        <NavigationBar />
      </Header>
      <Content className="bg-white">
        <div className="bg-white w-full flex flex-col flex-grow justify-center items-center">
          <h1 className="font-ltc-bodoni-175 text-5xl pt-20 text-black text-center">
            Relevant Research
          </h1>
          <p className=" font-proxima-nova text-2xl font-thin mt-10 mx-20 text-center">
            Below is a list of published research that indicates the potential
            of leveraging the transcriptomic profile to improve treatment
            outcomes.
          </p>
          <div className="w-full min-h-full mt-10 py-10 mb-20 flex flex-col justify-center items-center">
            <div className="w-10/12 h-auto bg-[#F1F1EF] rounded-md justify-center items-center">
              <p className="text-black hover:text-black font-proxima-nova text-lg font-thin mx-6 py-10 text-justify flex justify-start items-start">
                1. Identification of human triple-negative breast cancer
                subtypes and preclinical models for selection of targeted
                therapies. *The Journal of Clinical Investigation*, 121(7),
                2750-2767.{" "}
              </p>
            </div>
            <div className="w-10/12 h-auto bg-[#F1F1EF] mt-10 rounded-md">
              <p className="text-black hover:text-black font-proxima-nova text-lg font-thin mx-6 py-10 text-justify flex justify-start items-start">
                2. Comprehensive genomic analysis identifies novel subtypes and
                targets of triple-negative breast cancer.{" "}
              </p>
            </div>
            <div className="w-10/12 h-auto bg-[#F1F1EF] mt-10 rounded-md">
              <p className="text-black hover:text-black font-proxima-nova text-lg font-thin mx-6 py-10 text-justify flex justify-start items-start">
                3. Triple-negative breast cancer: challenges and opportunities
                of a heterogeneous disease. *Nature Reviews Clinical Oncology*,
                13(11), 674-690.
              </p>
            </div>
            <div className="w-10/12 h-auto bg-[#F1F1EF] mt-10 rounded-md">
              <p className="text-black hover:text-black font-proxima-nova text-lg font-thin mx-6 py-10 text-justify flex justify-start items-start">
                4. Differential response to neoadjuvant chemotherapy among 7
                triple-negative breast cancer molecular subtypes. *Clinical
                Cancer Research*, 19(19), 5533-5540.
              </p>
            </div>
            <div className="w-10/12 h-auto bg-[#F1F1EF] mt-10 rounded-md">
              <p className="text-black hover:text-black font-proxima-nova text-lg font-thin mx-6 py-10 text-justify flex justify-start items-start">
                5. Relationship between tumor infiltrating lymphocyte (TIL)
                levels and response to pembrolizumab (pembro) in metastatic
                triple-negative breast cancer (mTNBC): Results from KEYNOTE-086.
                *Annals of Oncology*, 30, v858-v859.
              </p>
            </div>
            <div className="w-10/12 h-auto bg-[#F1F1EF] mt-10 rounded-md">
              <p className="text-black hover:text-black font-proxima-nova text-lg font-thin mx-6 py-10 text-justify flex justify-start items-start">
                6. **Shah SP et al.** (2012). The clonal and mutational
                evolution spectrum of primary triple-negative breast cancers.
                *Nature*, 486(7403), 395-399.
              </p>
            </div>
          </div>
        </div>
      </Content>
      <Footer className="p-0 bg-white">
        <AppFooter />
      </Footer>
    </Layout>
  </>
);
