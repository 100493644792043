import React from "react";
import Marquee from "react-marquee-slider";

const timesArray = Array.from({ length: 4 }, (_, index) => index);
export const TextMarquee = (props) => {
  let velocity = props.velocity;
  if (window.innerWidth <= 1170) {
    velocity = 2;
  } else {
    velocity = 5;
  }

  return (
    <>
      <Marquee velocity={velocity} direction={props.direction}>
        {timesArray.map((id) => (
          <>
            <div className="flex justify-between mb-2">
              {props.text.split("").map((char, index) => (
                <span
                  className="text-[#ffffff85] text-sm leading-[5px] flex whitespace-nowrap font-proxima-nova font-thinfont-semibold"
                  key={index}
                >
                  {char}
                </span>
              ))}
            </div>
          </>
        ))}
      </Marquee>
    </>
  );
};
